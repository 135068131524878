
const rules = {
    buildRequired: (name: string) => [
        (v: string) => !!v || `${name} is required`,
    ],
    email: [
        (v: string) => !!v || 'E-mail is required',
        (v: string) => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    password: [
        (v: string) => !!v || 'Password is required',
        (v: string) => v.length >= 8 || 'Password must be 8 or more characters'
    ],

};

export default rules;
