
import { CurrentUser, NewUser } from '@/types';
import Vue from '@/vueTyped';
import rules from '@/validation-rules';
import debounce from 'lodash/debounce';
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import PhoneInput from '@/components/PhoneInput.vue';
import ThirdPartyLogInButtons from '@/pages/Account/ThirdPartyLogInButtons.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default Vue.extend({
    components: {
        ValidationProvider,
        ValidationObserver,
        PhoneInput,
        ThirdPartyLogInButtons,
        TransitionExpand,
    },

    props: {
        embedded: Boolean,
        clientGroupInviteToken: { type: String, default: null },
        allowedEmailDomains: { type: [Array, () => null], default: null },
        hideThirdPartyOptions: Boolean,
    },

    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                email: this.$store.state.account.visitorEmail,
                mobilePhone: '',
                smsPermitted: true,
                password: '',
            },
            rules,
            checkingEmail: false,
            emailInUseError: '',
            registrationInProgress: false,
        };
    },

    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },

        emailDomainsHint(): string | null {
            if (!this.allowedEmailDomains || this.allowedEmailDomains.length === 0) return null;
            return `${this.$t('allowedDomains')} ${this.allowedEmailDomains.map(domain => `@${domain}`).join(', ')}`;
        },

        emailDomainsError(): string | null {
            const emailPresent = this.form.email.match(/.+@.+\..+/) !== null;
            if (!emailPresent) return null;

            if (!this.allowedEmailDomains || this.allowedEmailDomains.length === 0) return null;

            const domainOkay = this.allowedEmailDomains.some(domain => this.form.email.endsWith(`@${domain}`));
            if (domainOkay) return null;

            return this.emailDomainsHint;
        },

        currentUserWasJustCreated(): boolean {
            // Note, not reactive to time;
            // this will only reset when currentUser changes,
            // but that should be enough for this case.
            const veryRecently = new Date();
            veryRecently.setSeconds(veryRecently.getSeconds() - 5);
            return this.currentUser !== null && new Date(this.currentUser.createdAt) >= veryRecently;
        },
    },

    methods: {
        async handleEmailChange() {
            const emailValid = await (this.$refs.emailRef as any).validateSilent();

            if (emailValid.valid) {
                this.validateEmail(this.form.email);
            }
        },

        validateEmail: debounce(async function(this: any, email) {
            this.checkingEmail = true;
            this.emailInUseError = '';
            const result = await this.$store.dispatch('checkEmailValidity', email);
            this.checkingEmail = false;
            if (result.error) {
                this.emailInUseError = this.$t('account.addressInUse');
            }
        }, 500),

        async register() {
            this.registrationInProgress = true;

            const notifyMethod: NewUser['communicationPreferences']['notifyMethod'] = ['email', 'app'];
            if (this.form.smsPermitted) {
                notifyMethod.push('sms');
            }

            const newUser: NewUser = {
                firstName: this.form.firstname,
                lastName: this.form.lastname,
                email: this.form.email,
                mobilePhone: this.form.mobilePhone,
                password: this.form.password,
                addressComponents: {},
                communicationPreferences: { notifyMethod },
                signupSource: this.$store.state.platform,
            };

            const { error } = await this.$store.dispatch('accountRegister', {
                ...newUser,
                clientGroupInviteToken: this.clientGroupInviteToken
            });

            this.registrationInProgress = false;

            if (error) {
                this.$store.dispatch('alertUser', {
                    type: 'error',
                    message: this.$t('account.addressInUse'),
                });
            } else {
                this.$emit('success');
            }
        },

        signOut() {
            this.$store.dispatch('accountLogOut');
        },
    },
});
