
import Vue from '@/vueTyped';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default Vue.extend({
    props: {
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            iti: null as intlTelInput.Plugin | null,
        };
    },

    computed: {
        input: {
            get(): HTMLInputElement | null {
                return this.$el.querySelector('input');
            }
        },
    },

    mounted() {
        if (this.input) {
            this.input.placeholder = '';
            this.iti = intlTelInput(this.input, {
                nationalMode: false,
                preferredCountries: ['us', 'ca', 'mx'],
                utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.4/js/utils.min.js',
            });
        } else {
            console.error('No input element on PhoneInput component mount');
        }
    },

    beforeDestroy() {
        this.iti?.destroy();
    },

    methods: {
        handleBlur() {
            this.$emit('input', this.iti?.getNumber());
        }
    },
});
