
import Vue from '@/vueTyped';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { APPLE_OAUTH2_REDIRECT_URL } from '@/config';
import RouteNames from '@/router/names';
import { RawLocation } from 'vue-router';

export default Vue.extend({
    props: {
        signUp: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            response: null as any,
            error: null as any,
        };
    },

    computed: {
        notAndroid(): boolean {
            // Apple sign in is not currently supported on Android.
            // https://github.com/capacitor-community/apple-sign-in/issues/13
            return this.$store.state.platform !== 'android';
        },
    },

    mounted() {
        GoogleAuth.initialize();
    },

    methods: {
        async signInWithApple() {
            try {
                const authorization = await SignInWithApple.authorize({
                    clientId: 'org.iseechange.www', // This is a service ID, not the app ID!
                    redirectURI: APPLE_OAUTH2_REDIRECT_URL,
                    scopes: 'name email',
                    // state: '',
                    nonce: Math.random().toString(16).split('.')[1], // Maybe not used? Not sure.
                });

                this.response = authorization.response;

                const { identityToken, givenName, familyName } = authorization.response;

                if (identityToken) {
                    await this.$store.dispatch('accountSocialLogin', {
                        provider: 'apple',
                        idToken: identityToken,
                        accessToken: identityToken,
                        firstName: givenName,
                        lastName: familyName,
                    });

                    this.handleSignIn();
                } else {
                    throw new Error('Apple identity token not found');
                }
            } catch (error) {
                this.handleError(error);
            }
        },

        async signInWithFacebook() {
            try {
                const response = await FacebookLogin.login({ permissions: ['email', 'public_profile'] });
                this.response = response;

                if (response.accessToken?.token) {
                    const tokenResponse = await FacebookLogin.getCurrentAccessToken();

                    if (tokenResponse.accessToken) {
                        const { token } = tokenResponse.accessToken;
                        await this.$store.dispatch('accountSocialLogin', {
                            provider: 'facebook',
                            accessToken: token,
                        });

                        this.handleSignIn();
                    } else {
                        throw new Error('Facebook access token not found');
                    }
                }
            } catch (error) {
                this.handleError(error);
            }
        },

        async signInWithGoogle() {
            try {
                const response = await GoogleAuth.signIn();
                this.response = response;

                if (response.authentication) {
                    const { error } = await this.$store.dispatch('accountSocialLogin', {
                        provider: 'google',
                        idToken: response.authentication.idToken,
                        accessToken: response.authentication.accessToken,
                    });

                    if (error) {
                        throw error;
                    }

                    this.handleSignIn();
                } else {
                    throw new Error(`Google id token not found in ${JSON.stringify(response.authentication)}`);
                }
            } catch (error) {
                this.handleError(error);
            }
        },

        handleError(error: Error) {
            this.$store.dispatch('alertUser', {
                type: 'error',
                message: 'There was a problem using third party sign-in. This can happen as a side effect of ad blockers or strict privacy settings.'
            });

            this.error = error; // For dev only!

            console.error(error);
        },

        handleSignIn() {
            const user = this.$store.state.account.currentUser;

            if (user) {
                let nextRoute: RawLocation = String(this.$route.query.redirect ?? '/');

                if (nextRoute.includes('://')) {
                    location.assign(nextRoute);
                }

                if (!user.addressComponents.countryCode) {
                    nextRoute = {
                        name: RouteNames.REGISTER,
                        query: this.$route.query,
                    };
                }

                this.$router.push(nextRoute);
            } else {
                throw new Error('Not signed in!');
            }
        },
    },
});
